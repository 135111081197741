import { ArrowBackIosNew } from '@mui/icons-material'
import { IconButton } from '@mui/material'

export default ({ handleBack, paddingLeft }) => {
  return (
    <div className='flex' style={{ paddingLeft: paddingLeft ?? 16 }}>
      <IconButton onClick={handleBack} sx={{ color: '#d65944' }}>
        <ArrowBackIosNew />
      </IconButton>
    </div>
  )
}
